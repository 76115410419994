import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "status-theme" }
const _hoisted_2 = { class: "status-theme__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["status-theme__icon-rounded", _ctx.isUpdated ? 'status-theme__updated' : 'status-theme__not-updated'])
    }, null, 2),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.descriptionStatus), 1)
  ]))
}