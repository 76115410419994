import { ThemeInterface } from '@/models/theme/Theme.interface';
import {
  Setting,
  Current,
  Info,
} from '@/models/structure-settings/StructureSettings.interface';
import { ImageInterface } from '@/models/api-client/image';
import { CustomPageInterface } from '@/models/custom-page/CustomPage.interface';
import {
  MutationTree,
  ActionTree,
  ActionContext,
  Module,
  GetterTree,
} from 'vuex';
import {
  CustomResolution,
  ImageKeyUrl,
  Resolution,
} from '@/models/store/config';
import { ChangeLog } from '@/models/change-log';

/**
 * @name StatusLoading
 * @description - Interface de status de loading
 * @type {StatusLoading}
 */
type StatusLoading = 'initial' | 'loading' | 'error' | 'not-found';

/**
 * @name KeyLoading
 * @description - Interface de chave de loading dos campos da store
 * @type {KeyLoading}
 */
type KeyLoading =
  | 'initial'
  | 'page'
  | 'theme'
  | 'setting'
  | 'image'
  | 'preview';

/**
 * @name KeyComponent
 * @description - Interface de chave de loading de componentes.
 * @type {KeyComponent}
 */
type KeyComponent = 'initial' | 'theme-list-page';

/**
 * @name StatusSaving
 * @description - Interface de status de salvamento.
 * @type {StatusSaving}
 */
type StatusSaving = 'initial' | 'saving' | 'saved' | 'error';

/**
 * @name KeySaving
 * @description - Interface de chave de salvamento.
 * @type {string}
 */
type KeySaving = 'setting' | 'image';

/**
 * @name LoadingState
 * @description - Interface de estado de loading.
 * @type {LoadingState}
 */
type LoadingState = {
  status: StatusLoading;
  component: KeyComponent;
  key: KeyLoading;
};

/**
 * @name SavingState
 * @description - Interface de estado de salve.
 * @type {SavingState}
 */
type SavingState = {
  status: StatusSaving;
  key: KeySaving;
};

/**
 * @name Theme
 * @description - Interface de tema.
 * @type {Theme}
 */
export type Theme = {
  id: string;
  name: string;
  version: string;
  code: string;
  publishedDate: string;
  lastUpdate: string;
  isUpdated: boolean;
  snapshot: string;
  published: boolean;
  purchase: boolean;
  current: Current;
  urlPreview: string;
  urlStore: string;
};

/**
 * @name ThemeEditorModule
 * @description - Interface de módulo do editor de temas.
 * @type {ThemeEditorModule}
 */
export type ThemeEditorModule = Module<State, any>;

/**
 * @name SettingState
 * @description - Interface de estado do módulo de settings.
 * @type {SettingState}
 */
export type SettingState = {
  updated: boolean;
  list: Setting[];
  active: Setting;
  current: Current;
};

/**
 * @name State
 * @description - Interface de estado do módulo ThemeEditor.
 * @type {State}
 */
export interface State {
  page: {
    updated: boolean;
    list: CustomPageInterface[];
    defaultList: CustomPageInterface[];
    active: CustomPageInterface;
  };
  theme: {
    sort: 'asc' | 'desc';
    updated: boolean;
    list: Theme[];
    selected: Theme;
    published: Theme;
    info: Info;
  };
  preview: {
    url: URL | null;
    template: string;
    resolution: Resolution;
    resolutions: Resolution[];
  };
  image: {
    updated: boolean;
    list: ImageInterface[];
    logo: ImageKeyUrl[];
    seal: ImageKeyUrl[];
    button: ImageKeyUrl[];
  };
  setting: SettingState;
  changeLog: {
    list: ChangeLog[];
  };
  loading: {
    initialLoading: LoadingState;
    page: LoadingState;
    theme: LoadingState;
    image: LoadingState;
    setting: LoadingState;
    preview: LoadingState;
  };
  saving: {
    image: SavingState;
    setting: SavingState;
  };
}

export interface Getters extends GetterTree<State, any> {
  /**
   * @name themeLoading
   * @param state {State} - Estado da store
   * @description Retorna a configuração de loading da store theme
   * @return LoadingState
   */
  themeLoading: (state: State) => LoadingState;

  /**
   * @name themeList
   * @param state {State} - Estado da store
   * @description Retorna a lista de temas da store
   * @return Theme[] - Lista de temas
   */
  themeList: (state: State) => Theme[];

  /**
   * @name themeGetPublished
   * @param state {State} - Estado da store
   * @description Retorna o tema publicado
   * @return Theme - Tema publicado
   */
  themeGetPublished: (state: State) => Theme;

  /**
   * @name themeGetSelected
   * @param state {State} - Estado da store
   * @description Retorna o tema selecionado
   * @return Theme - Tema selecionado
   */
  themeGetSelected: (state: State) => Theme;

  /**
   * @name themeGetSelectedInfo
   * @param state {State} - Estado da store
   * @description Retorna informações do tema selecionado
   * @return Info - Informações do tema selecionado
   */
  themeGetSelectedInfo: (state: State) => Info;

  /**
   * @name themeGetCurrent
   * @param state {State} - Estado da store
   * @description Retorna as configurações do tema atual
   * @return Current - Objeto com as configurações do tema atual
   */
  themeGetCurrent: (state: State) => Current;

  /**
   * @name pageLoading
   * @param state {State} - Estado da store
   * @description Retorna a configuração de loading da store page
   * @return LoadingState
   */
  pageLoading: (state: State) => LoadingState;

  /**
   * @name pageList
   * @param state {State} - Estado da store
   * @description Retorna a lista de páginas da store
   * @return CustomPageInterface[] - Lista de páginas
   */
  pageList: (state: State) => CustomPageInterface[];

  /**
   * @name pageActive
   * @param state {State} - Estado da store
   * @description Retorna a página ativa ou selecionada
   * @return CustomPageInterface - Página ativa ou selecionada
   */
  pageActive: (state: State) => CustomPageInterface;

  /**
   * @name imageLoading
   * @param state {State} - Estado da store
   * @description Retorna a configuração de loading da store image
   * @return LoadingState
   */
  imageLoading: (state: State) => LoadingState;

  /**
   * @name imageSaving
   * @param state {State} - Estado da store
   * @description Retorna a configuração de save da store image
   * @return SavingState
   */
  imageSaving: (state: State) => SavingState;

  /**
   * @name imageListLogo
   * @param state {State} - Estado da store
   * @description Retorna a lista de imagens padrões dos logos.
   * @return ImageKeyUrl[]
   */
  imageListLogo: (state: State) => ImageKeyUrl[];

  /**
   * @name imageActiveLogo
   * @param state {State} - Estado da store
   * @description Retorna o logo ativo para desktop.
   * @return ImageKeyUrl[]
   */
  imageActiveLogo: (state: State) => ImageKeyUrl | undefined;

  /**
   * @name imageListSeals
   * @param state {State} - Estado da store
   * @description Retorna a lista de imagens padrões dos selos.
   * @return ImageKeyUrl[]
   */
  imageListSeals: (state: State) => ImageKeyUrl[];

  /**
   * @name imageListButtons
   * @param state {State} - Estado da store
   * @description Retorna a lista de imagens padrões dos botões .
   * @return ImageKeyUrl[]
   */
  imageListButtons: (state: State) => ImageKeyUrl[];

  /**
   * @name settingLoading
   * @param state {State} - Estado da store
   * @description Retorna a configuração de loading da store setting
   * @return LoadingState
   */
  settingLoading: (state: State) => LoadingState;

  /**
   * @name settingSaving
   * @param state {State} - Estado da store
   * @description Retorna a configuração de save da store settings
   * @return SavingState
   */
  settingSaving: (state: State) => SavingState;

  /**
   * @name settingList
   * @param state {State} - Estado da store
   * @description  Retorna a lista de configurações do tema selecionado
   * @return Setting[] - Lista de configurações
   */
  settingList: (state: State) => Setting[];

  /**
   * @name settingGetActive
   * @param state {State} - Estado da store
   * @description  Retorna a sessão que está ativa ou selecionada
   * @return Setting[] - Sessão de configurações
   */
  settingGetActive: (state: State) => Setting;

  /**
   * @name initialLoading
   * @param state {State} - Estado da store
   * @description Retorna a configuração de loading inicial
   * @return string - Retorna o status do loading inicial
   */
  initialLoading: (state: State) => string;

  /**
   * @name previewLoading
   * @param state {State} - Estado da store
   * @description Retorna a configuração de loading da store preview
   * @return LoadingState - Retorna o status do loading do preview
   */
  previewLoading: (state: State) => LoadingState;

  /**
   * @name previewGetTemplate
   * @param state {State} - Estado da store
   * @description Retorna o template do preview
   * @return string - Template do preview
   */
  previewGetTemplate: (state: State) => string;

  /**
   * @name previewSelectedResolution
   * @param state {State} - Estado da store
   * @description Retorna a resolução selecionada no preview
   * @return Resolution - Resolução selecionada
   */
  previewSelectedResolution: (state: State) => Resolution;

  /**
   * @name previewResolutions
   * @param state {State} - Estado da store
   * @description Retorna as resoluções do preview
   * @return Resolution[] - Resoluções do preview
   */
  previewResolutions: (state: State) => Resolution[];

  /**
   * @name changeLogsList
   * @param state {State} - Estado da store
   * @description Retorna a lista de alterações de um tema selecionado.
   * @return ChangeLog[] - Retorna a lista de alterações de um tema selecionado.
   */
  changeLogsList: (state: State) => ChangeLog[];
}

export enum Mutations {
  /**
   * @name THEME_SET_LIST
   * @description - Mutation para salvar na store a lista de temas vinda da api.
   */
  THEME_SET_LIST = 'THEME_SET_LIST',

  /**
   * @name THEME_SET_BY_ID
   * @description - Mutation para salvar no store o tema vindo da api que foi buscado por ID.
   */
  THEME_SET_BY_ID = 'THEME_SET_BY_ID',

  /**
   * @name THEME_SET_INFO
   * @description - Mutation para salvar no store as informações do tema.
   */
  THEME_SET_INFO = 'THEME_SET_INFO',

  /**
   * @name THEME_SELECT
   * @description - Mutation para salvar no store o tema selecionado.
   */
  THEME_SELECT = 'THEME_SELECT',

  /**
   * @name THEME_RESET_SELECTED
   * @description - Mutation para resetar o tema selecionado.
   */
  THEME_RESET_SELECTED = 'THEME_RESET_SELECTED',

  /**
   * @name THEME_CHANGE_PUBLISHED_LOCAL
   * @description - Mutation para publicar um tema da lista local.
   */
  THEME_CHANGE_PUBLISHED_LOCAL = 'THEME_CHANGE_PUBLISHED_LOCAL',

  /**
   * @name THEME_REMOVE_LIST_LOCAL
   * @description - Mutation para remover um tema da lista local.
   */
  THEME_REMOVE_LIST_LOCAL = 'THEME_REMOVE_LIST_LOCAL',

  /**
   * @name THEME_RENAME_LOCAL
   * @description - Mutation para renomear um tema da lista local.
   */
  THEME_RENAME_LOCAL = 'THEME_RENAME_LOCAL',

  /**
   * @name THEME_SET_PREVIEW
   * @description - Mutation para selecionar um tema para preview.
   */
  THEME_SET_PREVIEW = 'THEME_SET_PREVIEW',

  /**
   * @name PAGE_SET_ACTIVE
   * @description - Mutation para ativar ou selecionar uma página.
   */
  PAGE_SET_ACTIVE = 'PAGE_SET_ACTIVE',

  /**
   * @name PAGE_SET_LIST
   * @description - Mutation para salvar na store a lista de paginas customizadas vindas da api.
   */
  PAGE_SET_LIST = 'PAGE_SET_LIST',

  /**
   * @name IMAGE_SET_LIST
   * @description - Mutation para salvar na store a lista de imagens vinda da api.
   */
  IMAGE_SET_LIST = 'IMAGE_SET_LIST',

  /**
   * @name IMAGE_UPDATE_LOCAL
   * @description - Mutation para atualizar a imagem na lista local.
   */
  IMAGE_UPDATE_LOCAL = 'IMAGE_UPDATE_LOCAL',

  /**
   * @name SETTINGS_SET_STRUCTURE
   * @description - Mutation para salvar na store a estrutura de configurações de um tema.
   */
  SETTINGS_SET_STRUCTURE = 'SETTINGS_SET_STRUCTURE',

  /**
   * @name SETTINGS_SET_ACTIVE_SESSION
   * @description - Mutation para ativar ou selecionar uma sessão do structure.
   */
  SETTINGS_SET_ACTIVE_SESSION = 'SETTINGS_SET_ACTIVE_SESSION',

  /**
   * @name SETTINGS_UPDATE_CURRENT
   * @description - Mutation para atualizar o campo selecionado da sessão do structure.
   */
  SETTINGS_UPDATE_CURRENT = 'SETTINGS_UPDATE_CURRENT',

  /**
   * @name PREVIEW_SET_RESOLUTION
   * @description - Mutation para selecionar uma resolução para o preview.
   */
  PREVIEW_SET_RESOLUTION = 'PREVIEW_SET_RESOLUTION',

  /**
   * @name PREVIEW_UPDATE_CUSTOM_RESOLUTION
   * @description - Mutation para atualizar uma resolução customizada.
   */
  PREVIEW_UPDATE_CUSTOM_RESOLUTION = 'PREVIEW_UPDATE_CUSTOM_RESOLUTION',

  /**
   * @name PREVIEW_RESET_DATA
   * @description - Mutation para resetar os dados do preview.
   */
  PREVIEW_RESET_DATA = 'PREVIEW_RESET_DATA',

  /**
   * @name CHANGE_LOG_PUBLISH
   * @description - Mutation para registrar logs de publicar.
   */
  CHANGE_LOG_PUBLISH = 'CHANGE_LOG_PUBLISH',

  /**
   * @name CHANGE_LOG_DELETE
   * @description - Mutation para registrar logs de deletar..
   */
  CHANGE_LOG_DELETE = 'CHANGE_LOG_DELETE',

  /**
   * @name CHANGE_LOG_DUPLICATE
   * @description - Mutation para registrar logs de duplicate.
   *
   */
  CHANGE_LOG_DUPLICATE = 'CHANGE_LOG_DUPLICATE',

  /**
   * @name CHANGE_LOG_RENAME
   * @description - Mutation para registrar logs de rename.
   */
  CHANGE_LOG_RENAME = 'CHANGE_LOG_RENAME',
}

export interface MutationsInterface extends MutationTree<any> {
  /**
   * @name [Mutations.THEME_SET_LIST]
   *
   * @param state {State} - Estado do store
   * @param themes {ThemeInterface[]} - Lista de temas
   * @description Seta a lista de temas no estado
   * @return void
   */
  [Mutations.THEME_SET_LIST](state: State, themes: Theme[]): void;

  /**
   * @name [Mutations.THEME_SET_BY_ID]
   * @param state {State} - Estado do store
   * @param theme {ThemeInterface} - Tema
   * @description Seta o tema que foi buscado da api selecionado no estado
   * @return void
   */
  [Mutations.THEME_SET_BY_ID](state: State, theme: Theme): void;

  /**
   * @name [Mutations.THEME_SET_INFO]
   * @param state {State} - Estado do store
   * @param info {Info} - Informações do tema
   * @description Seta as informações do tema selecionado no estado
   * @return void
   */
  [Mutations.THEME_SET_INFO](state: State, info: Info): void;

  /**
   * @name [Mutations.THEME_SELECT]
   * @param state {State} - Estado do store
   * @param themeId {string} - ID do tema
   * @description Seta o tema selecionado no estado
   * @return void
   */
  [Mutations.THEME_SELECT](state: State, themeId: string): void;

  /**
   * @name [Mutations.THEME_RESET_SELECTED]
   * @param state {State} - Estado do store
   * @description Remove o tema selecionado da store.
   * @return void
   */
  [Mutations.THEME_RESET_SELECTED](state: State): void;

  /**
   * @name [Mutations.THEME_CHANGE_PUBLISHED_LOCAL]
   * @param state {State} - Estado do store
   * @param id {string} - ID do tema
   * @description Seta o tema publicado no estado
   * @return void
   */
  [Mutations.THEME_CHANGE_PUBLISHED_LOCAL](state: State, id: string): void;

  /**
   * @name [Mutations.THEME_REMOVE_LIST_LOCAL]
   * @param state {State} - Estado do store
   * @param id {string} - ID do tema
   * @description Remove o tema selecionado do lista de temas
   * @return void
   */
  [Mutations.THEME_REMOVE_LIST_LOCAL](state: State, id: string): void;

  /**
   * @name [Mutations.THEME_RENAME_LOCAL]
   * @param state {State} - Estado do store
   * @param payload {UpdateName} - Objeto com o ID e o novo nome do tema
   * @description Renomeia o tema selecionado da lista de temas local
   * @return void
   */
  [Mutations.THEME_RENAME_LOCAL](state: State, payload: UpdateName): void;

  /**
   * @name [Mutations.THEME_SET_PREVIEW]
   * @param state {State} - Estado do store
   * @param preview {ThemeInterface} - Objeto com as informações do preview
   * @description Seta o preview selecionado no estado
   * @return void
   */
  [Mutations.THEME_SET_PREVIEW](state: State, preview: ThemeInterface): void;

  /**
   * @name [Mutations.PAGE_SET_ACTIVE]
   * @param state {State} - Estado do store
   * @param pages {CustomPageInterface} - Página selecionada
   * @description Seta a página selecionada no estado
   * @return void
   */
  [Mutations.PAGE_SET_ACTIVE](state: State, pages: CustomPageInterface): void;

  /**
   * @name [Mutations.PAGE_SET_LIST]
   * @param state {State} - Estado do store
   * @param pages {CustomPageInterface[]} - Lista de páginas
   * @description Seta a lista de páginas no estado
   * @return void
   */
  [Mutations.PAGE_SET_LIST](state: State, pages: CustomPageInterface[]): void;

  /**
   * @name [Mutations.IMAGE_SET_LIST]
   * @param state {State} - Estado do store
   * @param images {ImageInterface[]} - Lista de imagens
   * @description Seta a lista de imagens no estado
   * @return void
   */
  [Mutations.IMAGE_SET_LIST](state: State, images: ImageInterface[]): void;

  /**
   * @name [Mutations.IMAGE_UPDATE_LOCAL]
   * @param state {State} - Estado do store
   * @param image { path: string; file: File } - Atualiza a imagem selecionada na lista de imagens local.
   * @description Seta a lista de imagens no estado
   * @return void
   */
  [Mutations.IMAGE_UPDATE_LOCAL](
    state: State,
    image: { name: string; path: 'logo' | 'seal' | 'button'; file: File }
  ): void;

  /**
   * @name [Mutations.SETTINGS_SET_STRUCTURE]
   * @param state {State} - Estado do store
   * @param structure {Setting[]} - Lista de configurações do tema
   * @description Seta a lista de configurações do tema no estado
   * @return void
   */
  [Mutations.SETTINGS_SET_STRUCTURE](state: State, structure: Setting[]): void;

  /**
   * @name [Mutations.SETTINGS_SET_ACTIVE_SESSION]
   * @param state {State} - Estado do store
   * @param session {Setting} - Sessão da structure de configurações do tema
   * @description Seta uma sessão da structure de configurações do tema no estado
   * @return void
   */
  [Mutations.SETTINGS_SET_ACTIVE_SESSION](state: State, session: Setting): void;

  /**
   * @name [Mutations.SETTINGS_UPDATE_CURRENT]
   * @param state {State} - Estado do store
   * @param field {Current} - Campo da sessão do structure
   * @description Atualiza o campo selecionado da sessão do structure com o valor do input.
   * @return void
   */
  [Mutations.SETTINGS_UPDATE_CURRENT](state: State, field: Current): void;

  /**
   * @name [Mutations.PREVIEW_SET_RESOLUTION]
   * @param state {State} - Estado do store
   * @param resolution {Resolution} - Resolução do preview
   * @description Seta a resolução do preview no estado
   * @return void
   */
  [Mutations.PREVIEW_SET_RESOLUTION](
    state: State,
    resolution: Resolution
  ): void;

  /**
   * @name [Mutations.PREVIEW_UPDATE_CUSTOM_RESOLUTION]
   * @param state {State} - Estado do store
   * @param field {CustomResolution} - Campo da resolução customizada
   * @description Atualiza um campo da resolução customizada do preview
   * @return void
   */
  [Mutations.PREVIEW_UPDATE_CUSTOM_RESOLUTION](
    state: State,
    field: CustomResolution
  ): void;

  /**
   * @name [Mutations.PREVIEW_RESET_DATA]
   * @param state {State} - Estado do store
   * @description Reseta todos os dados do preview
   * @return void
   */
  [Mutations.PREVIEW_RESET_DATA](state: State): void;

  /**
   * @name [Mutations.CHANGE_LOG_PUBLISH]
   * @param state {State} - Estado do store
   * @description Seta o log de publicação do tema
   * @return void
   */
  [Mutations.CHANGE_LOG_PUBLISH](state: State): void;

  /**
   * @name [Mutations.CHANGE_LOG_DELETE]
   * @param state {State} - Estado do store
   * @description Seta o log de exclusão do tema
   * @return void
   */
  [Mutations.CHANGE_LOG_DELETE](state: State): void;

  /**
   * @name [Mutations.CHANGE_LOG_RENAME]
   * @param state {State} - Estado do store
   * @description Seta o log de renomeação do tema
   * @return void
   */
  [Mutations.CHANGE_LOG_RENAME](state: State): void;

  /**
   * @name [Mutations.CHANGE_LOG_DUPLICATE]
   * @param state {State} - Estado do store
   * @description Seta o log de duplicação do tema
   * @return void
   */
  [Mutations.CHANGE_LOG_DUPLICATE](state: State): void;
}

export type Context = ActionContext<State, any>;
type UpdateName = {
  id: string;
  name: string;
};

export interface ActionsInterface extends ActionTree<State, any> {
  /**
   * @name apiThemeGetList
   * @param context {Context} - Contexto de ações do Vuex
   * @description Buscar a lista de temas na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiThemeGetList: (ctx: Context) => Promise<void>;

  /**
   * @name apiThemeRemove
   * @param context {Context} - Contexto de ações do Vuex
   * @param id {string} - Parâmetro de identificação do tema
   * @description Remover um tema da api.
   * @return Promise<void> - Promise com o resultado da remoção
   */
  apiThemeRemove: (ctx: Context, id: string) => Promise<void>;

  /**
   * @name apiThemePublish
   * @param context {Context} - Contexto de ações do Vuex
   * @param id {string} - Parâmetro de identificação do tema
   * @description Publicar um tema na api.
   * @return Promise<void> - Promise com o resultado da publicação
   */
  apiThemePublish: (ctx: Context) => Promise<void>;

  /**
   * @name apiThemeDuplicate
   * @param context {Context} - Contexto de ações do Vuex
   * @param id {string} - Parâmetro de identificação do tema
   * @description Duplicar um tema na api.
   * @return Promise<void> - Promise com o resultado da duplicação
   */
  apiThemeDuplicate: (ctx: Context) => Promise<void>;

  /**
   * @name apiThemeUpdateName
   * @param context {Context} - Contexto de ações do Vuex
   * @param payload {UpdateName} - Parâmetro com id e nome do tema.
   * @description Atualizar o nome de um tema na api.
   * @return Promise<void> - Promise com o resultado da atualização
   */
  apiThemeUpdateName: (ctx: Context, name: string) => Promise<void>;

  /**
   * @name apiThemeGetById
   * @param context {Context} - Contexto de ações do Vuex
   * @param id {string} - Parâmetro de identificação do tema
   * @description Buscar um tema na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiThemeGetById: (ctx: Context, id: string) => Promise<void>;

  /**
   * @name apiThemeGetInfo
   * @param context {Context} - Contexto de ações do Vuex
   * @param id {string} - Parâmetro de identificação do tema
   * @description Buscar informações de um tema na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiThemeGetInfo: (ctx: Context, id: string) => Promise<void>;

  /**
   * @name apiThemeGetPreview
   * @param context {Context} - Contexto de ações do Vuex
   * @param url {string} - Parâmetro de url do tema do preview
   * @description Buscar o template do preview na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiThemeGetPreview: (ctx: Context) => Promise<void>;

  /**
   * @name apiImageGetList
   * @param context {Context} - Contexto de ações do Vuex
   * @description Buscar a lista de imagens na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiImageGetList: (ctx: Context) => Promise<void>;

  /**
   * @name apiImageUpdate
   * @param context {Context} - Contexto de ações do Vuex
   * @param image { path: string; file: File } - imagem com o path da api e o arquivo.
   * @description Atualiza a imagem selecionada na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiImageUpdate: (
    ctx: Context,
    image: { name: string; path: 'logo' | 'seal' | 'button'; file: File }
  ) => Promise<void>;

  /**
   * @name apiPageGetList
   * @param context {Context} - Contexto de ações do Vuex
   * @description Buscar a lista de páginas na api.
   * @return Promise<void> - Promise com o resultado da busca
   */
  apiPageGetList: (ctx: Context) => Promise<void>;

  /**
   * @name apiPageChangePagePreview
   * @param context {Context} - Contexto de ações do Vuex
   * @param page {CustomPageInterface} - Pagina selecionada
   * @description Alterar a página de preview.
   * @return void - Promise com o resultado da busca
   */
  apiPageChangePagePreview: (ctx: Context, page: CustomPageInterface) => void;

  /**
   * @name apiSettingGetStructure
   * @param context {Context} - Contexto de ações do Vuex
   * @param themeId {string} - Parâmetro de identificação do tema
   * @description Buscar a lista de configurações do tema na api.
   * @return void - Sem retorno
   */
  apiSettingGetStructure: (ctx: Context, id: string) => Promise<void>;

  /**
   * @name apiSettingSaveStructure
   * @param context {Context} - Contexto de ações do Vuex
   * @description Salvar as configurações do tema na api.
   * @return void - Sem retorno
   */
  apiSettingSaveStructure: (ctx: Context) => Promise<void>;

  /**
   * @name apiSettingSaveCurrentActive
   * @param context {Context} - Contexto de ações do Vuex
   * @param field {Current} - Campo com chave e valor atualizado.
   * @description Salvar as configurações do sessão do tema que foram alterados pelo input.
   * @return void - Sem retorno
   */
  apiSettingSaveCurrentActive: (ctx: Context, field: Current) => Promise<void>;

  /**
   * @name initLoadDataPreview
   * @param context {Context} - Contexto de ações do Vuex
   * @description Carrega os dados das configurações iniciais do preview.
   * @return void - Sem retorno
   */
  initLoadDataPreview: (ctx: Context, id: string) => void;

  /**
   * @name initLoadDataThemeList
   * @param context {Context} - Contexto de ações do Vuex
   * @description Carrega os dados das configurações iniciais da pagina de listagem.
   * @return void - Sem retorno
   */
  initLoadDataThemeList: (ctx: Context) => void;

  /**
   * @name themeSelect
   * @param context {Context} - Contexto de ações do Vuex
   * @param themeId {string} - Parâmetro de identificação do tema
   * @description Selecionar um tema.
   * @return void - Sem retorno
   */
  themeSelect: (ctx: Context, themeId: string) => void;
}
