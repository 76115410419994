import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "unpublished-theme-button" }
const _hoisted_2 = { class: "unpublished-theme-button__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["unpublished-theme-button__button", _ctx.addStyleInButton]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitEvent && _ctx.emitEvent(...args)))
    }, [
      (_ctx.type === 'buy')
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "unpublished-theme-button__buy",
            name: "shopping-cart",
            prefix: "fas"
          }))
        : (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            class: "unpublished-theme-button__publish",
            name: "upload",
            prefix: "fas"
          })),
      _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1)
    ], 2)
  ]))
}