
import {
  defineComponent,
  onMounted,
  onActivated,
  onDeactivated,
  ref,
} from 'vue';
import Icon from '@/components/Icon.vue';
import InputFile from '@/components/defaults/input-file/InputFile.vue';
import { mapActions, mapGetters } from 'vuex';
import { useScrollPosition, useRemoveLastSeparator } from '@/support';
export default defineComponent({
  name: 'ButtonEditor',
  components: {
    Icon,
    InputFile,
  },
  emits: ['close', 'set-component'],
  setup() {
    const contentButton = ref<HTMLElement>(document.createElement('div'));
    const { restorePosition, savePosition, listenForElementScrollEvent } =
      useScrollPosition(contentButton);
    onActivated(() => {
      restorePosition();
    });
    onDeactivated(() => {
      savePosition();
    });
    onMounted(() => {
      useRemoveLastSeparator(contentButton);
    });
    return {
      contentButton,
      listenForElementScrollEvent,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      imageListButtons: 'imageListButtons',
    }),
  },
  methods: {
    ...mapActions('themeEditor', {
      apiUpdateImage: 'apiImageUpdate',
    }),
    updateImage(image: { name: string; file: File }): void {
      this.apiUpdateImage({ path: 'button', ...image });
    },
    emitEventSetComponent(): void {
      this.$emit('set-component', 'SettingsEditor');
    },
    emitEventClose(): void {
      this.$emit('close');
    },
  },
});
