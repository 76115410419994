import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client';

import { ThemeApiClientUrlsInterface } from './ThemeApiClientUrls.interface';
import { ThemeApiClientInterface } from './ThemeApiClient.interface';
import { Theme } from '@/models/theme/Theme.interface';
import { TrayResponse } from '@/models/tray-response/TrayResponse.model';
import { Info } from '@/models/structure-settings/StructureSettings.interface';

/**
 * @Name ThemeApiClientModel
 * @description
 * Implementa a interface ThemeApiClientInterface
 */
export class ThemeApiClientModel implements ThemeApiClientInterface {
  private readonly urls!: ThemeApiClientUrlsInterface;

  constructor(urls: ThemeApiClientUrlsInterface) {
    this.urls = urls;
  }

  fetchTheme(url: string): Promise<TrayResponse<Theme>> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.fetchTheme,
      requiresToken: false,
      payload: { url },
    };

    return HttpClient.get<TrayResponse<Theme>>(params).then((data) => {
      data.data = new Theme(data.data);

      return data;
    });
  }

  /**
   * @Name getList
   * @description
   * Recupera lista de temas do opencode
   */
  getList(): Promise<TrayResponse<Theme[]>> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getList,
      requiresToken: false,
    };

    return HttpClient.get<TrayResponse<Theme[]>>(params).then((data) => {
      const themes = data.data.map((theme) => new Theme(theme));
      data.data = themes;

      return data;
    });
  }

  removeTheme(idTheme: string): Promise<Theme> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.removeTheme,
      requiresToken: true,
      payload: { idTheme },
    };
    return HttpClient.post<Theme>(params).then((data) => {
      return data;
    });
  }

  publishTheme(idTheme: string): Promise<Theme> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.publishTheme,
      requiresToken: true,
      payload: { idTheme },
    };
    return HttpClient.post<Theme>(params).then((data) => {
      return data;
    });
  }

  duplicateTheme(idTheme: string): Promise<Theme> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.duplicateTheme,
      requiresToken: true,
      payload: { idTheme },
    };
    return HttpClient.post<Theme>(params).then((data) => {
      return data;
    });
  }

  updateName(payload: { id: string; name: string }): Promise<Theme> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.updateName + '/' + payload.id + '/name',
      requiresToken: true,
      payload: { name: payload.name },
    };
    return HttpClient.post<Theme>(params).then((data) => {
      return data;
    });
  }

  getThemeById(themeId: string): Promise<TrayResponse<Theme>> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getThemeById,
      requiresToken: true,
      payload: { theme_id: themeId },
    };
    return HttpClient.get<TrayResponse<Theme>>(params).then((data) => {
      return data;
    });
  }

  getInfo(themeId: string): Promise<TrayResponse<Info>> {
    const params: HttpRequestParamsInterface = {
      url: this.urls.getInfo,
      requiresToken: false,
      payload: { theme_id: themeId },
    };
    return HttpClient.get<TrayResponse<Info>>(params).then((data) => {
      return data;
    });
  }
}
