import { ThemeInterface } from '@/models/theme/Theme.interface';
import { Theme } from '@/models/store/theme-editor';

export function generateThemeFromState(themeApi: ThemeInterface): Theme {
  return {
    id: themeApi.id || '',
    name: themeApi.name || '',
    code: themeApi.code || '',
    lastUpdate: '--/--/--',
    published: themeApi.published === '1',
    isUpdated: true,
    publishedDate: '--/--/--',
    purchase: true,
    version: '-.-.-',
    snapshot: 'https://via.placeholder.com/358x200',
    urlPreview: '',
    urlStore: '',
    ...JSON.parse(themeApi.settings || '{}'),
  };
}
