
import Breadcrumb from '@/components/Breadcrumb.vue';
import CustomResolution from '@/components/display/CustomFields.vue';
import ThemePreview from '@/components/opencode-theme/OpencodeTheme.vue';
import StatusSaveTemplate from '@/components/status-theme-save/StatusSaveTemplate.vue';
import DataLayer from '@/components/DataLayer.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import LoadingPage from '@/components/LoadingPage.vue';
import SettingsEditor from '@/components/settings/SettingsEditor.vue';
import ButtonEditor from '@/components/button/ButtonEditor.vue';
import LogoEditor from '@/components/logo/LogoEditor.vue';
import SealEditor from '@/components/seal/SealEditor.vue';
import SessionsEditor from '@/components/sessions/SessionsEditor.vue';
import useSidebarEditor from '@/layouts/module';
import { useRoute } from 'vue-router';
import { defineComponent, ref, onErrorCaptured, watch } from 'vue';
import { Mutations } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'TrayThemeEditor',
  components: {
    Breadcrumb,
    StatusSaveTemplate,
    CustomResolution,
    ThemePreview,
    DataLayer,
    LoadingPage,
    SettingsEditor,
    ButtonEditor,
    LogoEditor,
    SealEditor,
    SessionsEditor,
  },
  setup() {
    const newIsOpen = ref(true);
    const route = useRoute();
    const { componentName, setComponentName, resetComponentName } =
      useSidebarEditor();
    const error = ref<Error>();
    onErrorCaptured((erro: Error) => {
      error.value = erro;
    });
    function closeSidebar(): void {
      newIsOpen.value = false;
    }
    function openSidebar(): void {
      newIsOpen.value = true;
    }
    function verifyComponentName(name: string): boolean {
      return componentName.value === name && newIsOpen.value;
    }
    watch(
      () => route?.name,
      (currentRoute, oldRoute) => {
        if (currentRoute === 'Pré visualização') {
          closeSidebar();
          return;
        }
        if (!oldRoute) {
          componentName.value = 'SettingsEditor';
        }
        openSidebar();
      },
      {
        immediate: true,
      }
    );
    return {
      error,
      setComponentName,
      newIsOpen,
      closeSidebar,
      openSidebar,
      verifyComponentName,
      resetComponentName,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      settingSaving: 'settingSaving',
    }),
    buttonBackEditor(): string {
      return this.$route.name === 'Pré visualização'
        ? 'button-back-editor'
        : '';
    },
  },
  beforeMount() {
    this.resetPreviewState();
  },
  mounted() {
    const id = this.$route.params.id;
    this.initLoadDataPreview(id);
  },
  methods: {
    ...mapActions('themeEditor', {
      initLoadDataPreview: 'initLoadDataPreview',
    }),
    ...mapMutations('themeEditor', {
      resetPreviewState: Mutations.PREVIEW_RESET_DATA,
    }),
  },
});
