import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "published-theme" }
const _hoisted_2 = { class: "published-theme__wrapper-left" }
const _hoisted_3 = { class: "wrapper-content" }
const _hoisted_4 = { class: "wrapper-content__header" }
const _hoisted_5 = { class: "wrapper-content__header-name" }
const _hoisted_6 = { class: "wrapper-content__header-version" }
const _hoisted_7 = { class: "wrapper-content__header-div-icon" }
const _hoisted_8 = { class: "wrapper-content__header" }
const _hoisted_9 = { class: "wrapper-content__header-code" }
const _hoisted_10 = { class: "wrapper-content" }
const _hoisted_11 = { class: "wrapper-content-footer" }
const _hoisted_12 = { class: "published-theme__wrapper-right" }
const _hoisted_13 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_StatusPublishedTheme = _resolveComponent("StatusPublishedTheme")!
  const _component_ButtonPublishedTheme = _resolveComponent("ButtonPublishedTheme")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.theme.name), 1),
          _createElementVNode("span", _hoisted_6, "(" + _toDisplayString(_ctx.$t('components.theme-display.template.version')) + _toDisplayString(_ctx.theme.version) + ")", 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Tooltip, {
              "test-id": "btn-edit-theme-name",
              info: _ctx.$t('components.theme-display.template.edit-theme-name')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  class: "wrapper-content__header-icon-pencil",
                  name: "edit",
                  prefix: "far",
                  onClick: _ctx.updateName
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["info"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('components.theme-display.template.theme-code')) + ": " + _toDisplayString(_ctx.theme.id), 1)
        ])
      ]),
      _createElementVNode("main", _hoisted_10, [
        _createVNode(_component_StatusPublishedTheme, {
          "is-updated": _ctx.theme.isUpdated
        }, null, 8, ["is-updated"]),
        _withDirectives(_createVNode(_component_ButtonPublishedTheme, {
          "test-id": "btn-update-version",
          label: _ctx.$t('components.theme-display.template.update-version'),
          class: "button-theme__outlined button-theme__ml-md button-theme__sm",
          onClick: _ctx.updateThemeVersion
        }, null, 8, ["label", "onClick"]), [
          [_vShow, !_ctx.theme.isUpdated]
        ])
      ]),
      _createElementVNode("footer", _hoisted_11, [
        _createVNode(_component_ButtonPublishedTheme, {
          "test-id": "btn-change-log",
          label: _ctx.$t('components.theme-display.template.change-log'),
          class: "button-theme__flat button-theme__xl",
          onClick: _ctx.themeChangeLog
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "wrapper-content-footer__icon-list",
              name: "list",
              prefix: "fas"
            })
          ]),
          _: 1
        }, 8, ["label", "onClick"]),
        _createVNode(_component_ButtonPublishedTheme, {
          "test-id": "btn-duplicate-theme",
          label: _ctx.$t('components.theme-display.template.duplicate-theme'),
          class: "button-theme__flat button-theme__lg",
          onClick: _ctx.duplicateTheme
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "wrapper-content-footer__icon-copy",
              name: "copy",
              prefix: "far"
            })
          ]),
          _: 1
        }, 8, ["label", "onClick"]),
        _createVNode(_component_ButtonPublishedTheme, {
          "test-id": "btn-edit-theme",
          label: _ctx.$t('components.theme-display.template.edit-theme'),
          class: "button-theme__outlined button-theme__md",
          onClick: _ctx.editTheme
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "wrapper-content-footer__icon-edit",
              name: "edit",
              prefix: "far"
            })
          ]),
          _: 1
        }, 8, ["label", "onClick"])
      ])
    ]),
    _createElementVNode("main", _hoisted_12, [
      _createElementVNode("div", {
        class: "wrapper-content",
        onMouseover: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.hoverImage = true), ["prevent"])),
        onMouseleave: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.hoverImage = false), ["prevent"]))
      }, [
        _createElementVNode("img", {
          alt: _ctx.$t('components.theme-display.template.theme-snapshot-image'),
          src: _ctx.theme.snapshot,
          class: "wrapper-content__image-preview"
        }, null, 8, _hoisted_13),
        _withDirectives(_createVNode(_component_ButtonPublishedTheme, {
          "test-id": "btn-view-store",
          label: _ctx.$t('components.theme-display.template.view-store'),
          class: "wrapper-content__button-preview button-theme__flat button-theme__lg",
          onClick: _ctx.viewStore
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, {
              class: "icon-play",
              name: "play",
              prefix: "fas"
            })
          ]),
          _: 1
        }, 8, ["label", "onClick"]), [
          [_vShow, _ctx.hoverImage]
        ])
      ], 32)
    ])
  ]))
}