
import { Timers } from '@/enums';
import InputFileError from './components/InputFileError.vue';
import InputFileEmpty from './components/InputFileEmpty.vue';
import InputFilePreview from './components/InputFilePreview.vue';
import InputFileLoading from './components/InputFileLoading.vue';
import InputFileInfo from './components/InputFileInfo.vue';
import { useInputFile, InputFileInterface } from './module';
import { defineComponent, PropType } from 'vue';

interface Event<T = EventTarget> {
  target: T;
}

export default defineComponent({
  name: 'InputFile',
  components: {
    InputFileError,
    InputFileEmpty,
    InputFilePreview,
    InputFileLoading,
    InputFileInfo,
  },
  props: {
    testKey: {
      type: String,
      default: '',
    },
    inputFields: {
      type: Object as PropType<InputFileInterface>,
      required: true,
    },
    urlImage: {
      type: String,
      default: '',
    },
  },
  emits: ['callback', 'update-setting-current'],
  setup(props) {
    const { label, type, description, field, validation, tab, changeFile } =
      useInputFile(
        props.inputFields.label,
        props.inputFields.type,
        props.inputFields.description,
        props.inputFields.field,
        props.inputFields.validation,
        props.inputFields.tab
      );
    return {
      label,
      type,
      description,
      field,
      validation,
      tab,
      changeFile,
    };
  },
  data() {
    return {
      image: '',
      message: {
        field: '',
        error: '',
      },
      status: {
        field: '',
        loading: 'initial',
      },
    };
  },
  computed: {
    required(): boolean {
      const stringValidation = this.validation.split('|');
      const stringRequiredPosition = stringValidation.slice(0, 1);
      const stringRequired = stringRequiredPosition.toString();
      const requiredValidation = stringRequired.split(':').slice(1);
      if (requiredValidation.toString() == 'true') {
        return true;
      }
      return false;
    },
    classInputError(): string {
      return this.message.field === this.field && this.message.error
        ? 'error_load_image'
        : '';
    },
    isLoading(): boolean {
      return this.status.loading === 'loading';
    },
    isExistImageAndNotLoading(): boolean {
      return this.image && !this.isLoading;
    },
    isErrorToField(): boolean {
      return this.message.field === this.field && this.message.error;
    },
  },
  mounted() {
    this.message.field = this.field;
    this.status.field = this.field;
    this.loadImageInToData();
  },
  methods: {
    loadImageInToData(): void {
      if (this.urlImage.length) {
        this.image = this.urlImage;
      }
    },
    callback(event: Event<HTMLInputElement>): void {
      this.resetError();
      const name = event.target.name;
      const file = event.target.files?.item(0);
      if (!file) {
        return;
      }
      this.changeStatusLoading('loading');
      const fileObject = this.changeFile(name, file);
      this.$emit('callback', fileObject);
      this.image = URL.createObjectURL(file);
      this.$emit('update-setting-current', {
        key: this.field,
        value: fileObject,
      });
      setTimeout(() => {
        this.changeStatusLoading('initial');
      }, Timers.AWAIT6000MS);
    },
    removeImage(): void {
      this.image = '';
    },
    resetError(): void {
      this.message.error = '';
    },
    handlerError(error: string, reset = false): void {
      this.message.error = error;
      if (!reset) return;
      setTimeout(() => {
        this.resetError();
      }, Timers.AWAIT6000MS);
    },
    changeStatusLoading(loading: string): void {
      this.status.loading = loading;
    },
    onLoadImageError(): void {
      this.handlerError(this.$t('defaults.inputs-file.error.file-load'));
    },
  },
});
