import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "changelog-theme__header" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "changelog-theme__header-info-theme" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.snapshot,
      alt: _ctx.$t('components.sidebar.changelog.image-alt'),
      class: "changelog-theme__header-image"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", null, _toDisplayString(_ctx.name || _ctx.$t('components.sidebar.changelog.nameless-theme')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.sidebar.changelog.theme-code')) + ": " + _toDisplayString(_ctx.id), 1)
    ])
  ]))
}