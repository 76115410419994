
import Tooltip from '@/components/defaults/tooltip/Tooltip.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChangeLogListItem',
  components: {
    Tooltip,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    hour: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  computed: {
    showTooltipBasedOnUserNameSize(): boolean {
      return this.username.length >= 34;
    },
  },
});
