import { ApiClientInterface } from '@/models/api-client/ApiClient.interface';
import ThemeApiClient from './theme';
import LogoApiClient from './logo';
import SealApiClient from './seal';
import ButtonApiClient from './button';
import CustomPageApiClient from './custom-page';
import DataLayerApiClient from './data-layer';
import StructureSettingsApiClient from './structure-settings';
import ImageApiClient from './image';

const apiClient: ApiClientInterface = {
  theme: ThemeApiClient,
  logo: LogoApiClient,
  seal: SealApiClient,
  button: ButtonApiClient,
  customPage: CustomPageApiClient,
  dataLayer: DataLayerApiClient,
  structureSettings: StructureSettingsApiClient,
  images: ImageApiClient,
};

export default apiClient;
