
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'InputFilePreview',
  components: {
    Icon,
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: '',
    },
  },
  emits: ['load-image-error'],
  methods: {
    onLoadImageError(): void {
      this.$emit('load-image-error');
    },
  },
});
