import { reactive } from 'vue';
import { Auth } from '@/models/http-client/interceptors/requests';
import { Change, ChangeLog } from '@/models/change-log/Changelog.interface';
import { i18n } from '@/plugins/i18n';

interface UseChangelog {
  generateChangeLog: (description: Change, theme: string) => ChangeLog;
}
const $t = i18n.global.t;
const changes = (change: Change): string => {
  const changes = {
    create: $t('changelog.create'),
    update: $t('changelog.update'),
    publish: $t('changelog.publish'),
    duplicate: $t('changelog.duplicate'),
    rename: $t('changelog.rename'),
    delete: $t('changelog.delete'),
    default: $t('changelog.default'),
  };
  return changes[change] || changes['default'];
};

const state = reactive({
  changeLog: [] as ChangeLog[],
  currentUser: '',
});

export const useChangeLog = (): UseChangelog => {
  function getCurrentUser(): void {
    const auth = new Auth();
    state.currentUser = auth.getCurrentUser();
  }

  function addZeroIfLessThanTen(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  function getCurrentDateAndHour(): { hour: string; date: string } {
    const date = new Date();
    const hour = addZeroIfLessThanTen(date.getHours());
    const minutes = addZeroIfLessThanTen(date.getMinutes());
    const seconds = addZeroIfLessThanTen(date.getSeconds());
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return {
      hour: `${hour}:${minutes}:${seconds}`,
      date: `${day}/${month}/${year}`,
    };
  }

  function generateChangeLog(description: Change, themeID: string): ChangeLog {
    getCurrentUser();
    const { hour, date } = getCurrentDateAndHour();
    const changeLog: ChangeLog = {
      id: themeID,
      username: state.currentUser,
      date,
      hour,
      description: changes(description),
    };
    state.changeLog.push(changeLog);
    return changeLog;
  }

  return {
    generateChangeLog,
  };
};
