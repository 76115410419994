
import Icon from '@/components/Icon.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'UnpublishedThemeButtonBuyOrPublish',
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function (value: string): boolean {
        return ['buy', 'publish'].indexOf(value) !== -1;
      },
    },
  },
  emits: ['theme-buy', 'theme-publish'],
  computed: {
    addStyleInButton(): string {
      return this.type === 'buy'
        ? 'button--green'
        : 'button--blue button--outlined';
    },
  },
  methods: {
    emitEvent(): void {
      this.type === 'buy'
        ? this.$emit('theme-buy')
        : this.$emit('theme-publish');
    },
  },
});
