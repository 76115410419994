
import { mapGetters } from 'vuex';
import Icon from '@/components/Icon.vue';
import EditThemeModal from '@/components/modals/edit-theme/EditThemeModal.vue';
import LoadingOpencode from '@/components/LoadingOpencode.vue';
import IframePreview from '@/components/opencode-theme/IframePreview.vue';

import { defineComponent } from 'vue';
import { Theme } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'OpencodeTheme',
  components: {
    Icon,
    EditThemeModal,
    LoadingOpencode,
    IframePreview,
  },
  data() {
    return {
      isCustomDrag: false,
      selectedTheme: {} as Theme,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      initialLoading: 'initialLoading',
      previewLoading: 'previewLoading',
    }),
    isLoadingIframe(): boolean {
      return (
        this.initialLoading === 'loading' ||
        this.previewLoading.status === 'loading'
      );
    },
  },
  methods: {
    themeEdit(): void {
      this.$refs.modalEditTheme.close();
    },
    initDrag(): void {
      console.info('Not implemented');
    },
  },
});
