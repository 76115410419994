
import BuyOrPublishButton from './UnpublishedThemeButtonBuyOrPublish.vue';
import MoreOptionsButton from './UnpublishedThemeButtonOptions.vue';
import PreviewButton from './UnpublishedThemeButtonPreview.vue';

import { defineComponent } from 'vue';
export default defineComponent({
  name: 'UnpublishedThemeItem',
  components: {
    BuyOrPublishButton,
    MoreOptionsButton,
    PreviewButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    lastUpdate: {
      type: String,
      required: true,
    },
    snapshot: {
      type: String,
      required: true,
    },
    purchase: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'theme-edit',
    'theme-html-edit',
    'theme-rename',
    'theme-change-log',
    'theme-duplicate',
    'theme-updated-version',
    'theme-delete',
    'theme-buy',
    'theme-publish',
    'theme-preview',
  ],
});
