
import ChangeLogItem from '@/components/sidebar/changelog-theme/ChangeLogListItem.vue';
import { ChangeLog } from '@/models/change-log/Changelog.interface';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ChangeLogList',
  components: {
    ChangeLogItem,
  },
  props: {
    changelog: {
      type: Object as PropType<ChangeLog[]>,
      required: true,
    },
  },
});
