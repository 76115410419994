
import Icon from '@/components/Icon.vue';
import SubSession from '@/components/sessions/Subsession.vue';
import Fields from '@/components/fields/Fields.vue';
import { mapGetters } from 'vuex';
import { useScrollPosition } from '@/support';
import { defineComponent, ref, onActivated, onDeactivated } from 'vue';

export default defineComponent({
  name: 'SessionsEditor',
  components: {
    Icon,
    SubSession,
    Fields,
  },
  emits: ['close', 'set-component'],
  setup() {
    const configDevSession = ref<HTMLElement>(document.createElement('div'));
    const { restorePosition, savePosition, listenForElementScrollEvent } =
      useScrollPosition(configDevSession);

    onActivated(() => {
      restorePosition();
    });
    onDeactivated(() => {
      savePosition();
    });

    return {
      configDevSession,
      listenForElementScrollEvent,
    };
  },
  data() {
    return {
      dropdown: '',
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      getSession: 'settingGetActive',
    }),
  },
  mounted() {
    const subSections = this.subSectionList();
    this.removeTheLastSeparatorInTheSubsectionsList(subSections);
  },
  methods: {
    subSectionList(): Element[] {
      return Array.from(
        document.getElementsByClassName('tray-theme-editor__content-subsession')
      );
    },
    removeTheLastSeparatorInTheSubsectionsList(subSections: Element[]): void {
      if (!subSections.length) return;
      for (const section of subSections) {
        const listHr = section.getElementsByClassName('separator-hr');
        if (!listHr) return;
        const last = listHr.item(listHr.length - 1) as Element;
        if (!last) return;
        last.setAttribute('style', 'visibility:hidden;');
      }
    },
  },
});
