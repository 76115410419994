
import { defineComponent } from 'vue';
import Icon from '@/components/Icon.vue';
import Tooltip from '@/components/defaults/tooltip/Tooltip.vue';

export default defineComponent({
  name: 'NavigationThemeList',
  components: {
    Icon,
    Tooltip,
  },
  props: {
    urlThemeReturnPainel: {
      type: String,
      required: true,
    },
    urlViewStore: {
      type: String,
      default: '',
    },
    urlThemeStore: {
      type: String,
      required: true,
    },
  },
  methods: {
    redirectToPainel: function (): void {
      window.open(this.urlThemeReturnPainel, '_blank');
    },
  },
});
