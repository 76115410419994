import { ActionsInterface, Mutations } from '@/models/store/theme-editor';
import apiClient from '@/api-client';
import { FormDataFields } from '@/enums';
import { generateThemeFromState, loading, saving } from '@/store/config';

const actions: ActionsInterface = {
  async apiThemeGetList(context): Promise<void> {
    loading(context, 'theme', 'loading');
    const urlPreview = new URL(context.rootGetters['user/storeGet'].urlPreview);
    const urlStore = new URL(context.rootGetters['user/storeGet'].urlStore);
    try {
      const response = await apiClient.theme.getList();
      const themes = response.data.map((themeApi) => {
        const theme = generateThemeFromState(themeApi);
        urlPreview.searchParams.set('opencode_theme', theme.code);
        urlStore.searchParams.set('opencode_theme', theme.code);
        theme.urlStore = urlStore.href;
        theme.urlPreview = urlPreview.href;
        return theme;
      });
      context.commit(Mutations.THEME_SET_LIST, themes);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível carregar a lista de temas.');
    }
  },
  async apiThemeRemove(context, id): Promise<void> {
    loading(context, 'theme', 'loading');
    try {
      await apiClient.theme.removeTheme(id);
      context.commit(Mutations.THEME_REMOVE_LIST_LOCAL, id);
      context.commit(Mutations.CHANGE_LOG_DELETE);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível remover o tema.');
    }
  },
  async apiThemePublish(context): Promise<void> {
    loading(context, 'theme', 'loading');
    try {
      const id = context.state.theme.selected.id;
      await apiClient.theme.publishTheme(id);
      context.commit(Mutations.THEME_CHANGE_PUBLISHED_LOCAL, id);
      context.commit(Mutations.CHANGE_LOG_PUBLISH);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível publicar o tema.');
    }
  },
  async apiThemeDuplicate(context): Promise<void> {
    loading(context, 'theme', 'loading');
    try {
      const id = context.state.theme.selected.id;
      await apiClient.theme.duplicateTheme(id);
      await context.dispatch('apiThemeGetList');
      context.commit(Mutations.CHANGE_LOG_DUPLICATE);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível duplicar o tema.');
    }
  },
  async apiThemeUpdateName(context, name): Promise<void> {
    loading(context, 'theme', 'loading');
    try {
      const payload = {
        id: context.getters.themeGetSelected.id,
        name: name,
      };
      await apiClient.theme.updateName(payload);
      context.commit(Mutations.THEME_RENAME_LOCAL, payload);
      context.commit(Mutations.CHANGE_LOG_RENAME);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível atualizar o nome do tema.');
    }
  },
  async apiThemeGetById(context, id): Promise<void> {
    loading(context, 'theme', 'loading');
    try {
      const response = await apiClient.theme.getThemeById(id);
      const theme = generateThemeFromState(response.data);
      const urlPreview = new URL(
        context.rootGetters['user/storeGet'].urlPreview
      );
      const urlStore = new URL(context.rootGetters['user/storeGet'].urlStore);
      urlPreview.searchParams.set('opencode_theme', theme.code);
      urlStore.searchParams.set('opencode_theme', theme.code);
      theme.urlStore = urlStore.href;
      theme.urlPreview = urlPreview.href;
      context.commit(Mutations.THEME_SET_BY_ID, theme);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível buscar o tema por id.');
    }
  },
  async apiThemeGetInfo(context, id): Promise<void> {
    loading(context, 'theme', 'loading');
    try {
      const response = await apiClient.theme.getInfo(id);
      context.commit(Mutations.THEME_SET_INFO, response.data);
      loading(context, 'theme', 'initial');
    } catch (e) {
      loading(context, 'theme', 'error');
      throw new Error('Não foi possível buscar as informações do tema.');
    }
  },
  async apiThemeGetPreview(context): Promise<void> {
    loading(context, 'preview', 'loading');
    const page = context.state.page.active;
    const url = new URL(context.getters.themeGetSelected.urlPreview);
    url.pathname = page.url;
    try {
      const response = await apiClient.theme.fetchTheme(url.href);
      context.commit(Mutations.THEME_SET_PREVIEW, response.data);
      loading(context, 'preview', 'initial');
    } catch (e) {
      loading(context, 'preview', 'error');
      throw new Error('Não foi possível buscar o preview do tema.');
    }
  },
  apiPageChangePagePreview(context, page): void {
    const themeActive = context.getters.themeGetSelected;
    context.commit(Mutations.PAGE_SET_ACTIVE, page, themeActive);
    void context.dispatch('apiThemeGetPreview');
  },
  async apiPageGetList(context): Promise<void> {
    loading(context, 'page', 'loading');
    try {
      const response = await apiClient.customPage.index();
      context.commit(Mutations.PAGE_SET_LIST, response.data);
      loading(context, 'page', 'initial');
    } catch (e) {
      loading(context, 'page', 'error');
      throw new Error('Não foi possível buscar a lista de páginas do tema.');
    }
  },
  async apiImageGetList(context): Promise<void> {
    loading(context, 'image', 'loading');
    try {
      const response = await apiClient.images.getImages();
      context.commit(Mutations.IMAGE_SET_LIST, response.data);
      loading(context, 'image', 'initial');
    } catch (e) {
      loading(context, 'image', 'error');
      throw new Error('Não foi possível buscar a lista de imagens.');
    }
  },
  async apiImageUpdate(context, payload): Promise<void> {
    context.commit(Mutations.IMAGE_UPDATE_LOCAL, payload);
    const { name, file, path } = payload;
    const formData = new FormData();
    formData.append(FormDataFields.IMAGE, file, file.name);
    formData.append(FormDataFields.IMAGE_TYPE, name);
    loading(context, 'image', 'loading');
    try {
      await apiClient.images.updateImage(path, formData);
      setTimeout(async () => {
        await context.dispatch('apiThemeGetPreview');
      }, 3000);
      loading(context, 'image', 'initial');
    } catch (e) {
      loading(context, 'image', 'error');
      throw new Error(
        `Não foi possível atualizar o campo ${name} com a imagem selecionada.`
      );
    }
  },
  async apiSettingGetStructure(context, id): Promise<void> {
    loading(context, 'setting', 'loading');
    try {
      const response = await apiClient.structureSettings.getSettings(id);
      context.commit(Mutations.SETTINGS_SET_STRUCTURE, response.data);
      loading(context, 'setting', 'initial');
    } catch (e) {
      const status = (e as any).response.status;
      if (status === 404) {
        loading(context, 'setting', 'not-found');
        throw new Error('O tema selecionado não possui structure.');
        return;
      }
      loading(context, 'setting', 'error');
      throw new Error(
        'Não foi possível buscar a lista de configurações do tema.'
      );
    }
  },
  async apiSettingSaveStructure(context): Promise<void> {
    loading(context, 'setting', 'loading');
    saving(context, 'setting', 'saving');
    try {
      const current = {
        theme_id: context.state.theme.selected.id,
        current: context.state.setting.current,
      };
      await apiClient.structureSettings.saveCurrentTheme(current);
      loading(context, 'setting', 'initial');
      saving(context, 'setting', 'saved');
    } catch (e) {
      loading(context, 'setting', 'error');
      saving(context, 'setting', 'error');
      throw new Error('Não foi possível salvar as configurações do tema.');
    }
  },
  async apiSettingSaveCurrentActive(context, field): Promise<void> {
    loading(context, 'setting', 'loading');
    saving(context, 'setting', 'saving');
    context.commit(Mutations.SETTINGS_UPDATE_CURRENT, field);
    try {
      const current = {
        theme_id: context.state.setting.current.theme_id,
        current: context.state.setting.current,
      };
      await apiClient.structureSettings.saveCurrentTheme(current);
      loading(context, 'setting', 'initial');
      saving(context, 'setting', 'saved');
      await context.dispatch('apiThemeGetPreview');
    } catch (e) {
      loading(context, 'setting', 'error');
      saving(context, 'setting', 'error');
      throw new Error('Não foi possível salvar as configurações do tema.');
    }
  },
  initLoadDataPreview(context, id) {
    loading(context, 'initialLoading', 'loading');

    context
      .dispatch('apiThemeGetById', id)
      .then(async () => {
        await context.dispatch('apiThemeGetPreview');
      })
      .catch((e) => {
        console.error(e);
      });

    Promise.all([
      context.dispatch('apiThemeGetInfo', id),
      context.dispatch('apiSettingGetStructure', id),
      context.dispatch('apiImageGetList'),
      context.dispatch('apiPageGetList'),
    ])
      .then(() => {
        loading(context, 'initialLoading', 'initial');
      })
      .catch((e) => {
        console.error(e);
        loading(context, 'initialLoading', 'error');
      });
  },
  initLoadDataThemeList(context) {
    loading(context, 'initialLoading', 'loading');
    Promise.all([
      context.dispatch('apiThemeGetList'),
      context.dispatch('apiImageGetList'),
    ])
      .then(() => {
        loading(context, 'initialLoading', 'initial');
      })
      .catch((e) => {
        console.error(e);
        loading(context, 'initialLoading', 'error');
      });
  },
  themeSelect(context, themeId): void {
    context.commit(Mutations.THEME_SELECT, themeId);
  },
};

export default actions;
