import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["test-id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "unpublished-theme-button-options",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCloseDropdown(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCloseDropdown(false)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["unpublished-theme-button-options__list-options", [_ctx.dropdown ? 'open' : 'close']])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("span", {
          key: index,
          "test-id": `btn-${option.event}`,
          class: _normalizeClass(["unpublished-theme-button-options__list-option", option.textColor]),
          onClick: ($event: any) => (_ctx.$emit(option.event))
        }, _toDisplayString(option.label), 11, _hoisted_1)), [
          [_vShow, option.show]
        ])
      }), 128))
    ], 2),
    _createElementVNode("button", {
      class: _normalizeClass(["unpublished-theme-button-options__button button--gray", [_ctx.dropdown ? 'active-button' : '']])
    }, [
      _createVNode(_component_Icon, {
        class: "unpublished-theme-button-options__button__icon-more",
        name: "ellipsis-v",
        prefix: "fas"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ], 2)
  ], 32))
}