
import { defineComponent } from 'vue';
import apiClient from '@/api-client';
import { DataLayerInfo } from '@/models/data-layer/DataLayer.interface';

export default defineComponent({
  name: 'DataLayer',
  setup() {
    async function requestDataLayer(): Promise<DataLayerInfo> {
      return apiClient.dataLayer.getStoreInfo();
    }

    async function getStoreInfo(): Promise<void> {
      const responseDataLayer = await requestDataLayer();
      (window as any).dataLayer.push(responseDataLayer.data);
    }
    getStoreInfo();

    return { requestDataLayer, getStoreInfo };
  },
});
