import { Getters } from '@/models/store/theme-editor';

const getters: Getters = {
  initialLoading: (state) => state.loading.initialLoading.status,
  themeLoading: (state) => state.loading.theme,
  themeList: (state) => {
    const notPublishedThemes = state.theme.list.filter(
      (theme) => !theme.published
    );
    return state.theme.sort === 'desc'
      ? notPublishedThemes.sort((a, b) => Number(b.id) - Number(a.id))
      : notPublishedThemes;
  },
  themeGetPublished: (state) => state.theme.published,
  themeGetSelected: (state) => state.theme.selected,
  themeGetSelectedInfo: (state) => state.theme.info,
  themeGetCurrent: (state) => state.theme.selected.current,
  pageLoading: (state) => state.loading.page,
  pageList: (state) => state.page.list.filter((page) => page.active !== ''),
  pageActive: (state) => state.page.active,
  imageLoading: (state) => state.loading.image,
  imageSaving: (state) => state.saving.image,
  imageActiveLogo: (state) =>
    state.image.logo.find((logo) => logo.key === 'store_logo'),
  imageListLogo: (state) => state.image.logo,
  imageListSeals: (state) => state.image.seal,
  imageListButtons: (state) => state.image.button,
  settingLoading: (state) => state.loading.setting,
  settingSaving: (state) => state.saving.setting,
  settingList: (state) => state.setting.list,
  settingGetActive: (state) => state.setting.active,
  previewLoading: (state) => state.loading.preview,
  previewGetTemplate: (state) => state.preview.template,
  previewSelectedResolution: (state) => state.preview.resolution,
  previewResolutions: (state) => state.preview.resolutions,
  changeLogsList: (state) => state.changeLog.list,
};

export default getters;
