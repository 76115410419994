
import SideBar from '@/components/sidebar/BaseSideBar.vue';
import ChangeLogList from '@/components/sidebar/changelog-theme/ChangeLogList.vue';
import ChangeLogThemeInfo from '@/components/sidebar/changelog-theme/ChangeLogInfoTheme.vue';
import ChangeLogListEmpty from '@/components/sidebar/changelog-theme/ChangeLogListEmpty.vue';
import { defineComponent, PropType } from 'vue';
import { ChangeLog } from '@/models/change-log';
import { Theme } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'ChangeLog',
  components: {
    SideBar,
    ChangeLogThemeInfo,
    ChangeLogList,
    ChangeLogListEmpty,
  },
  props: {
    theme: {
      type: Object as PropType<Theme>,
      required: true,
    },
    changelog: {
      type: Object as PropType<ChangeLog[]>,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    filterListByThemeId(): ChangeLog[] {
      return this.changelog.filter(
        (change: ChangeLog) => change.id === this.theme.id
      );
    },

    listSizeIsGreaterThanZero(): boolean {
      return this.filterListByThemeId.length > 0;
    },
  },
  methods: {
    open(): void {
      this.$refs.sidebar.open();
    },
    close(): void {
      this.$emit('close');
    },
  },
});
