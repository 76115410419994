import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex f-column a-center input-file-empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_Icon, {
      prefix: "far",
      name: "file-image",
      "aria-hidden": "true",
      class: "input-file-empty__icon"
    }),
    _createElementVNode("span", null, [
      _createElementVNode("u", null, _toDisplayString(_ctx.$t('defaults.inputs-file.click')), 1),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('defaults.inputs-file.add-image')), 1)
    ])
  ]))
}