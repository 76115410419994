
import { defineComponent, ref, onMounted } from 'vue';
import Icon from '@/components/Icon.vue';
import InputFile from '@/components/defaults/input-file/InputFile.vue';
import Alert from '@/components/defaults/alert/Alert.vue';
import { mapActions, mapGetters } from 'vuex';
import { useRemoveLastSeparator } from '@/support';

export default defineComponent({
  name: 'LogoEditor',
  components: {
    Icon,
    InputFile,
    Alert,
  },
  emits: ['close', 'set-component'],
  setup() {
    const contentLogo = ref<HTMLElement>(document.createElement('div'));
    onMounted(() => {
      useRemoveLastSeparator(contentLogo);
    });
    return {
      contentLogo,
    };
  },
  computed: {
    ...mapGetters('themeEditor', {
      imageListLogo: 'imageListLogo',
    }),
  },
  methods: {
    ...mapActions('themeEditor', {
      apiUpdateImage: 'apiImageUpdate',
    }),
    updateImage(image: { name: string; file: File }): void {
      this.apiUpdateImage({ path: 'logo', ...image });
    },
    emitEventSetComponent(): void {
      this.$emit('set-component', 'SettingsEditor');
    },
    emitEventClose(): void {
      this.$emit('close');
    },
  },
});
