export interface ThemeInterface {
  url: string;
  name?: string;
  template?: string;
  code?: string;
  id?: string;
  settings?: string;
  parent_id?: string;
  published?: string;
  current?: { [key: string]: any };
}

export class Theme implements ThemeInterface {
  public url = '';
  public name = '';
  public template = '';
  public code = '';
  public id = '';
  public settings = '';
  public parent_id = '';
  public published = '';

  constructor(dto: ThemeInterface) {
    Object.assign(this, dto);
  }
}
