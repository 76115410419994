
import { defineComponent } from 'vue';
import StoreInfo from '@/components/StoreInfo.vue';
import Icon from '@/components/Icon.vue';
import Tooltip from '@/components/defaults/tooltip/Tooltip.vue';
import { useEnv } from '@/support';
import { mapGetters, mapMutations } from 'vuex';
import { Mutations } from '@/models/store/theme-editor';

export default defineComponent({
  name: 'Breacrumb',
  components: {
    StoreInfo,
    Icon,
    Tooltip,
  },
  emits: ['close-sidebar'],
  setup() {
    const { isActiveEnvIs } = useEnv();
    const isProduction = isActiveEnvIs();
    return {
      isProduction,
    };
  },
  computed: {
    ...mapGetters('user', {
      urlsGet: 'urlsGet',
    }),
    isPreview(): boolean {
      return this.$route.name === 'Pré visualização';
    },
  },
  methods: {
    ...mapMutations('themeEditor', {
      resetSelectedTheme: Mutations.THEME_RESET_SELECTED,
    }),
    redirectToPanelListThemes(): void {
      window.open(this.urlsGet.panelListThemes, '_blank');
    },
    redirectToPage(route: string): void {
      this.$emit('close-sidebar');
      if (route === 'ThemeListPage' && this.isProduction) {
        this.redirectToPanelListThemes();
        this.resetSelectedTheme();
        return;
      }
      this.$router.push({ name: route });
    },
  },
});
