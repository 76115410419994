
import UnpublishedTheme from './UnpublishedThemeItem.vue';
import UnpublishedThemeSearchBar from './UnpublishedThemeSearchBar.vue';
import UnpublishedThemeAlertLimit from './UnpublishedThemeAlertLimit.vue';
import { UnpublishedThemeInterface, UnpublishedListThemes } from './module';
import ThemeDisplayEmpty from '@/components/theme-display-empty/ThemeDisplayEmpty.vue';
import RemoveOldThemesModal from '@/components/modals/remove-theme/RemoveOldThemesModal.vue';

import { defineComponent, PropType } from 'vue';
export default defineComponent({
  name: 'UnpublishedThemeListTemplate',
  components: {
    UnpublishedTheme,
    UnpublishedThemeSearchBar,
    UnpublishedThemeAlertLimit,
    ThemeDisplayEmpty,
    RemoveOldThemesModal,
  },
  props: {
    themes: {
      type: Array as PropType<UnpublishedThemeInterface[]>,
      required: true,
    },
  },
  emits: [
    'theme-edit',
    'theme-html-edit',
    'theme-rename',
    'theme-change-log',
    'theme-duplicate',
    'theme-updated-version',
    'theme-delete',
    'theme-buy',
    'theme-publish',
    'theme-preview',
  ],
  data() {
    return {
      search: '',
      showMore: false,
      componentKey: 0,
    };
  },
  computed: {
    sizeIsGreaterThan(): boolean {
      return this.themes.length > UnpublishedListThemes.VISIBLE_LIMIT;
    },
    searchThemesByNameOrCode(): UnpublishedThemeInterface[] {
      const value = this.search.toLowerCase();
      if (!value.length) return this.changeLimitVisible();
      return this.themes.filter(this.findByNameOrCode);
    },
    showLessThemesButton(): boolean {
      return (
        this.searchThemesByNameOrCode.length >=
        UnpublishedListThemes.VISIBLE_LIMIT
      );
    },
    showMoreThemesButton(): boolean {
      return (
        this.sizeIsGreaterThan && !this.showMore && this.showLessThemesButton
      );
    },
    showSearchNoThemes(): boolean {
      return this.searchThemesByNameOrCode.length == 0;
    },
  },
  methods: {
    clearFilter(): void {
      this.search = '';
      this.componentKey += 1;
    },
    showMoreItens(): void {
      this.showMore = true;
    },
    hiddenMoreItens(): void {
      this.showMore = false;
    },
    findByNameOrCode(theme: UnpublishedThemeInterface): any {
      if (
        theme.name.toLowerCase().includes(this.search.toLowerCase()) ||
        theme.id.toLowerCase().includes(this.search.toLowerCase())
      )
        return theme;
    },
    changeLimitVisible(): UnpublishedThemeInterface[] {
      return this.showMore
        ? this.themes
        : this.themes.slice(0, UnpublishedListThemes.VISIBLE_LIMIT);
    },
    themeEdit(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-edit', theme);
    },
    themeHtmlEdit(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-html-edit', theme);
    },
    themeRename(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-rename', theme);
    },
    themeChangeLog(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-change-log', theme);
    },
    themeDuplicate(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-duplicate', theme);
    },
    themeUpdatedVersion(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-updated-version', theme);
    },
    themeDelete(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-delete', theme);
    },
    themeBuy(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-buy', theme);
    },
    themePublish(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-publish', theme);
    },
    themePreview(theme: UnpublishedThemeInterface): void {
      this.$emit('theme-preview', theme);
    },
    openModalRemoveOld(): void {
      this.$refs.modalDeleteOldThemes.open();
    },
  },
});
