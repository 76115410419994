
import InputColor from '@/components/defaults/input-color/InputColor.vue';
import InputNumber from '@/components/defaults/input-number/InputNumber.vue';
import InputRadio from '@/components/defaults/input-radio/InputRadio.vue';
import InputCheckbox from '@/components/defaults/input-checkbox/InputCheckbox.vue';
import InputCheckboxAdicional from '@/components/defaults/input-checkbox/InputCheckboxAdicional.vue';
import InputSelect from '@/components/defaults/input-select/InputSelect.vue';
import InputToggle from '@/components/defaults/input-toggle/InputToggle.vue';
import InputFile from '@/components/defaults/input-file/InputFile.vue';
import InputTextarea from '@/components/defaults/input-textarea/InputTextarea.vue';
import InputEmail from '@/components/defaults/input-email/InputEmail.vue';
import InputTab from '@/components/defaults/input-tab/InputTab.vue';
import InputText from '@/components/defaults/input-text/InputText.vue';
import Alert from '@/components/defaults/alert/Alert.vue';
import { mapActions, mapGetters } from 'vuex';
import { defineComponent, PropType } from 'vue';
import { debounce } from '@/plugins/lodash';

export default defineComponent({
  name: 'Fields',
  components: {
    InputColor,
    InputNumber,
    InputRadio,
    InputCheckbox,
    InputCheckboxAdicional,
    InputSelect,
    InputToggle,
    InputFile,
    InputTextarea,
    InputEmail,
    InputTab,
    InputText,
    Alert,
  },
  props: {
    fields: {
      type: Array as PropType<any[]>,
      default: (): any => [],
    },
  },
  computed: {
    ...mapGetters('settings', {
      updateComponent: 'updateComponent',
    }),
  },
  methods: {
    ...mapActions('themeEditor', {
      apiSettingSaveCurrentActive: 'apiSettingSaveCurrentActive',
    }),
    updateFieldsInState(payload: { key: string; value: any }): void {
      debounce(() => this.apiSettingSaveCurrentActive(payload));
    },
  },
});
