import {
  MutationsInterface,
  Mutations,
  Theme,
} from '@/models/store/theme-editor';
import { useChangeLog } from '@/models/change-log';
const { generateChangeLog } = useChangeLog();

const mutations: MutationsInterface = {
  [Mutations.THEME_SET_LIST]: (state, themes) => {
    state.theme.list = themes.map((theme) => {
      if (theme.published) {
        state.theme.published = theme;
      }
      return theme;
    });
  },
  [Mutations.THEME_SET_BY_ID]: (state, theme) => {
    state.theme.selected = theme;
    const newCurrent = Object.assign({}, theme.current);
    newCurrent.theme_id = theme.id;
    state.setting.current = newCurrent;
  },
  [Mutations.THEME_SET_INFO]: (state, info) => {
    state.theme.info = info;
  },
  [Mutations.THEME_SELECT]: (state, themeId) => {
    const theme = state.theme.list.find((theme) => theme.id === themeId);
    state.theme.selected = theme ? theme : ({} as Theme);
    const newCurrent = Object.assign({}, theme?.current);
    newCurrent.theme_id = themeId;
    state.setting.current = newCurrent;
  },
  [Mutations.THEME_RESET_SELECTED]: (state) => {
    state.theme.selected = {} as Theme;
  },
  [Mutations.THEME_CHANGE_PUBLISHED_LOCAL]: (state, id) => {
    const theme = state.theme.list.find((theme) => theme.id === id);
    if (!theme) return;
    theme.published = true;
    state.theme.published = theme;
    state.theme.list.forEach((item) => {
      if (item.id !== id) item.published = false;
    });
  },
  [Mutations.THEME_REMOVE_LIST_LOCAL]: (state, id) => {
    const index = state.theme.list.findIndex((theme) => theme.id === id);
    if (index > -1) {
      state.theme.list.splice(index, 1);
    }
  },
  [Mutations.THEME_RENAME_LOCAL]: (state, { id, name }) => {
    const index = state.theme.list.findIndex((theme) => theme.id === id);
    if (index > -1) {
      state.theme.list[index].name = name;
    }
  },
  [Mutations.THEME_SET_PREVIEW]: (state, preview) => {
    state.preview.template = preview.template ? preview.template : '';
    state.preview.url = new URL(preview.url);
  },
  [Mutations.PAGE_SET_ACTIVE]: (state, page) => {
    state.page.active = page;
  },
  [Mutations.PAGE_SET_LIST]: (state, pages) => {
    state.page.list = state.page.defaultList.concat(pages);
  },
  [Mutations.IMAGE_SET_LIST]: (state, images) => {
    state.image.list = images;
    function updateImage(item: any): void {
      const index = images.findIndex((image) => image.Image.code === item.key);
      if (index > -1) {
        item.url = images[index].Image.logo;
        return;
      }
      item.url = '';
    }
    state.image.button.forEach(updateImage);
    state.image.logo.forEach(updateImage);
    state.image.seal.forEach(updateImage);
  },
  [Mutations.IMAGE_UPDATE_LOCAL]: (state, payload) => {
    function compareItem(image: any): any {
      return image.key === payload.name;
    }
    if (payload.path === 'button') {
      const index = state.image.button.findIndex(compareItem);
      state.image.button[index].url = URL.createObjectURL(payload.file);
      return;
    }
    if (payload.path === 'logo') {
      const index = state.image.logo.findIndex(compareItem);
      state.image.logo[index].url = URL.createObjectURL(payload.file);
      return;
    }
    if (payload.path === 'seal') {
      const index = state.image.seal.findIndex(compareItem);
      state.image.seal[index].url = URL.createObjectURL(payload.file);
      return;
    }
  },
  [Mutations.SETTINGS_SET_STRUCTURE]: (state, structure) => {
    const infoIndex = structure.findIndex((session) => session?.info);
    if (infoIndex >= 0) {
      structure.splice(infoIndex, 1);
    }
    state.setting.list = structure;
  },
  [Mutations.SETTINGS_SET_ACTIVE_SESSION]: (state, session) => {
    state.setting.active = session;
  },
  [Mutations.SETTINGS_UPDATE_CURRENT]: (state, field) => {
    const newCurrent = Object.assign({}, state.setting.current);
    newCurrent[field.key] = field.value;
    state.setting.current = newCurrent;
  },
  [Mutations.PREVIEW_SET_RESOLUTION]: (state, resolution) => {
    state.preview.resolution = Object.assign({}, resolution);
  },
  [Mutations.PREVIEW_UPDATE_CUSTOM_RESOLUTION]: (state, field) => {
    if (field.key === 'width') {
      state.preview.resolution.width = field.value;
      return;
    }
    state.preview.resolution.height = field.value;
  },
  [Mutations.PREVIEW_RESET_DATA]: (state) => {
    state.preview.template = '';
    state.preview.url = {} as URL;
  },
  [Mutations.CHANGE_LOG_PUBLISH]: (state) => {
    const themeID = state.theme.selected.id;
    state.changeLog.list.push(generateChangeLog('publish', themeID));
  },
  [Mutations.CHANGE_LOG_DUPLICATE]: (state) => {
    const themeID = state.theme.selected.id;
    state.changeLog.list.push(generateChangeLog('duplicate', themeID));
  },
  [Mutations.CHANGE_LOG_RENAME]: (state) => {
    const themeID = state.theme.selected.id;
    state.changeLog.list.push(generateChangeLog('rename', themeID));
  },
  [Mutations.CHANGE_LOG_DELETE]: (state) => {
    const themeID = state.theme.selected.id;
    state.changeLog.list.push(generateChangeLog('delete', themeID));
  },
};

export default mutations;
